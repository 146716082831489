import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { EditorScriptContext } from './types';

type LoyaltyUserPreferences = Partial<{
  addLoyaltyStatusWidgetPanel: {
    wasShown: boolean;
  };
}>;

const loyaltyUserPreferenceKeys: (keyof LoyaltyUserPreferences)[] = ['addLoyaltyStatusWidgetPanel'];

export const getUserPreferences = async (editorSDK: FlowEditorSDK): Promise<LoyaltyUserPreferences> => {
  return editorSDK.userPreferences.site.get('', loyaltyUserPreferenceKeys);
};

export const updateUserPreferences = async (editorSDK: FlowEditorSDK, preferences: LoyaltyUserPreferences) => {
  return editorSDK.userPreferences.site.set('', preferences);
};

export const markStatusWidgetPanelAsShown = async ({ editorSDK, flowAPI }: EditorScriptContext) => {
  const { errorMonitor } = flowAPI;

  errorMonitor.addBreadcrumb({ message: 'Marking status widget as shown' });
  await updateUserPreferences(editorSDK, {
    addLoyaltyStatusWidgetPanel: {
      wasShown: true,
    },
  });
};

export const wasStatusWidgetPanelShown = async (editorSDK: FlowEditorSDK) => {
  const userPreferences = await getUserPreferences(editorSDK);
  return userPreferences.addLoyaltyStatusWidgetPanel?.wasShown ?? false;
};
