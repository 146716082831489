import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import type { EditorReadyFn, FlowAPI, FlowEditorSDK, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import loyaltyPageComponent from './components/LoyaltyPage/.component.json';
import statusWidgetComponent from './components/LoyaltyStatus/.component.json';
import { Experiment } from './constants';
import {
  addStatusWidget,
  addStatusWidgetToStage,
  configureLoyaltyPageWidget,
  configureMyBusinessActions,
  configureStatusWidget,
  EditorPrivateApi,
  EditorPublicApi,
  EditorScriptContext,
  markStatusWidgetPanelAsShown,
  openAddStatusWidgetPanel,
  OpenAddStatusWidgetPanelParams,
  registerPageNavigationEvent,
} from './editor';

let _flowAPI: FlowAPI;

const _editorReady: EditorReadyFn = async (editorSDK, _appDefinitionId, { firstInstall, initialAppData }, flowAPI) => {
  const { applicationId } = initialAppData;
  const { errorMonitor, experiments } = flowAPI;
  const context: EditorScriptContext = { editorSDK, flowAPI };
  const useNonBlockingStatusWidgetInstall = experiments.enabled(Experiment.NonBlockingStatusWidgetInstall);

  _flowAPI = flowAPI;

  if (firstInstall) {
    errorMonitor.addBreadcrumb({ message: 'Starting app installation' });

    try {
      const pages = await editorSDK.document.pages.data.getAll('');
      const page = pages.find(({ tpaApplicationId }) => tpaApplicationId === applicationId);
      const pageRef = { id: page?.id!, type: 'DESKTOP' } as const;

      // Navigate to newly added page
      errorMonitor.addBreadcrumb({ message: 'Navigating to loyalty page' });
      await editorSDK.pages.navigateTo('', { pageRef });

      if (useNonBlockingStatusWidgetInstall) {
        addStatusWidgetToStage(context)
          .then(() => markStatusWidgetPanelAsShown(context))
          .catch((error) => flowAPI.reportError(error));
      } else {
        await addStatusWidgetToStage(context);
        await markStatusWidgetPanelAsShown(context);
      }
    } catch (error) {
      flowAPI.reportError(error as Error);
    }

    errorMonitor.addBreadcrumb({ message: 'Finished app installation' });
  } else {
    await registerPageNavigationEvent(context);
  }
};

const _getAppManifest: GetAppManifestFn = ({ appManifestBuilder }, editorSDK, _contextParams, flowAPI) => {
  const context: EditorScriptContext = { editorSDK, flowAPI };

  appManifestBuilder.configureWidget(loyaltyPageComponent.id, (widgetBuilder) => {
    configureLoyaltyPageWidget(context, widgetBuilder);
  });

  appManifestBuilder.configureWidget(statusWidgetComponent.id, (widgetBuilder) => {
    configureStatusWidget(context, widgetBuilder);
  });

  appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
    configureMyBusinessActions(context, managementActionsBuilder);
  });

  return appManifestBuilder.build();
};

const _exports = (editorSDK: FlowEditorSDK) => ({
  public: {
    openAddStatusWidgetPanel: async (params?: OpenAddStatusWidgetPanelParams) =>
      openAddStatusWidgetPanel({ context: { flowAPI: _flowAPI, editorSDK }, biOpenOrigin: params?.biOpenOrigin }),
  } satisfies EditorPublicApi,
  private: {
    addStatusWidget: async () => addStatusWidget({ flowAPI: _flowAPI, editorSDK }),
  } satisfies EditorPrivateApi,
});

export const { editorReady, getAppManifest, handleAction, onEvent, exports } = withMembersArea(
  {
    editorReady: _editorReady,
    getAppManifest: _getAppManifest,
    handleAction: async () => {},
    exports: _exports,
  },
  {
    membersAreaApps: [MA_APP_IDS.MY_REWARDS],
  },
);
