import { ComponentRef } from '@wix/yoshi-flow-editor';

import { WidgetId } from '../constants';
import { showProgressBar } from './showProgressBar';
import { EditorScriptContext } from './types';

export async function addStatusWidget(context: EditorScriptContext): Promise<void> {
  const { flowAPI } = context;
  const { t } = flowAPI.translations;
  let installError: unknown;
  let componentRef: ComponentRef | undefined;

  const { setNextProgressBarStep, hideProgressBar } = await showProgressBar(context, {
    title: t('status-widget.add-progress.title'),
    totalSteps: 1,
  });

  try {
    componentRef = await addStatusWidgetToStage(context);
    await setNextProgressBarStep();
  } catch (error) {
    installError = error;
  }

  await hideProgressBar(installError);

  if (installError) {
    throw installError;
  }

  if (componentRef) {
    setTimeout(() => revealStatusWidget(context, componentRef), 1250);
  }
}

export function addStatusWidgetToStage({ editorSDK, flowAPI }: EditorScriptContext): Promise<ComponentRef> {
  const { errorMonitor } = flowAPI;

  errorMonitor.addBreadcrumb({ message: 'Adding status widget' });
  return editorSDK.document.transactions.runAndWaitForApproval('', () =>
    addWidgetAndAdjustLayout({ flowAPI, editorSDK }),
  );
}

async function addWidgetAndAdjustLayout({ editorSDK, flowAPI }: EditorScriptContext): Promise<ComponentRef> {
  const { errorMonitor } = flowAPI;
  const { appDefinitionId, isClassicEditor } = flowAPI.environment;

  errorMonitor.addBreadcrumb({ message: 'Adding status widget component' });

  const { compId } = await editorSDK.tpa.add.component('', {
    appDefinitionId,
    managingAppDefId: appDefinitionId,
    componentType: 'WIDGET',
    widget: {
      widgetId: WidgetId.LoyaltyStatus,
    },
  });

  const componentRef = await editorSDK.components.getById('', { id: compId });

  if (!isClassicEditor) {
    errorMonitor.addBreadcrumb({ message: 'Adjusting status widget component layout' });

    const responsiveLayout = await editorSDK.document.responsiveLayout.get('', { componentRef });
    responsiveLayout.componentLayouts[0].width = { type: 'auto' };
    responsiveLayout.componentLayouts[0].minWidth = { type: 'px', value: 0 };
    responsiveLayout.componentLayouts[0].height = { type: 'auto' };
    responsiveLayout.componentLayouts[0].minHeight = { type: 'px', value: 0 };

    await editorSDK.document.responsiveLayout.update('', { componentRef, responsiveLayout });
  }

  return componentRef;
}

async function revealStatusWidget({ editorSDK }: EditorScriptContext, compRef: ComponentRef): Promise<void> {
  await editorSDK.editor.selection.selectComponentByCompRef('', { compsToSelect: [compRef] });
  await editorSDK.editor.selection.locateAndHighlightComponentByCompRef('', { compRef });
  setTimeout(() => editorSDK.editor.selection.clearHighlights(''), 2000);
}
